/**
 * @name: 商品品牌接口
 * @author: itmobai
 * @date: 2023-06-12 16:15
 * @description：商品品牌接口
 * @update: 2023-06-12 16:15
 */
import {get, postJ} from "@/request";
import {IProductBrand, IProductBrandParam} from "@/apis/product/brand/types";
import {IPageRes} from "@/apis/page";
import {ISort} from "@/apis/product/classification/types";

/**
 * 商品品牌查询
 * @param params 查询参数
 */
export const productBrandQueryApi = (params: IProductBrandParam) => get<IPageRes<IProductBrand[]>>("/golf/productBrand/query", params)
/**
 * 商品二级分类下拉列表
 */
export const productBrandGetSecondSortListApi = () => get<ISort[]>("/golf/productBrand/getSecondSortList")
/**
 * 商品品牌详情
 * @param id
 */
export const productBrandDetailApi = (id: string) => get<IProductBrand>("/golf/productBrand/detail/" + id)
/**
 * 商品品牌创建
 * @param data
 */
export const productBrandCreateApi = (data: IProductBrand) => postJ("/golf/productBrand/create", data)
/**
 * 商品品牌修改
 * @param data
 */
export const productBrandModifyApi = (data: IProductBrand) => postJ("/golf/productBrand/modify", data)
